import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {Contact, Structure} from '../../../model/structure.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ContactService} from '../../../services/contact.service';
import {LoaderService} from '../../../services/loader.service';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnChanges {

  @Output() actionEmitter: EventEmitter<void> = new EventEmitter<void>();

  @Input() contact: Contact;
  @Input() structure: Structure;
  @Input() readonly: boolean;

  form: FormGroup;

  constructor(private contactService: ContactService,
              private loaderService: LoaderService) {
    this.form = new FormGroup({
      intituleContact: new FormControl(null, Validators.required),
      addNom: new FormControl(false),
      nomPrenom: new FormControl(null),
      mail: new FormControl(null, [Validators.email]),
      mobile: new FormControl(null, [Validators.pattern('^\\+33 [6-7] \\d\\d \\d\\d \\d\\d \\d\\d$')]),
      telephone: new FormControl(null, [Validators.pattern('^\\+33 \\d \\d\\d \\d\\d \\d\\d \\d\\d$')]),
      fax: new FormControl(null, [Validators.pattern('^\\+33 \\d \\d\\d \\d\\d \\d\\d \\d\\d$')]),
    });
  }

  ngOnChanges() {
    this.form.reset();

    this.readonly ? this.form.disable() : this.form.enable();

    if (this.contact) {
      this.form.controls.intituleContact.setValue(this.contact.intituleContact);
      this.form.controls.addNom.setValue(this.contact.nomPrenom);
      this.form.controls.nomPrenom.setValue(this.contact.nomPrenom);
      this.form.controls.mail.setValue(this.contact.mail);
      this.form.controls.mobile.setValue(this.contact.mobile);
      this.form.controls.telephone.setValue(this.contact.telephone);
      this.form.controls.fax.setValue(this.contact.fax);
    }
  }

  onAddName() {
    if (!this.form.controls.addNom.value) {
      this.form.controls.nomPrenom.setValue('FAKE_VALUE');
    } else {
      this.form.controls.nomPrenom.setValue(null);
    }
  }

  save() {
    const contact: Contact = {
      contactId: this.contact ? this.contact.contactId : null,
      structureId: this.structure.structureId,
      intituleContact: this.form.controls.intituleContact.value,
      nomPrenom: this.form.controls.nomPrenom.value !== 'FAKE_VALUE' ? this.form.controls.nomPrenom.value : null,
      mail: this.form.controls.mail.value,
      telephone: this.form.controls.telephone.value,
      mobile: this.form.controls.mobile.value,
      fax: this.form.controls.fax.value,
      isPublic: this.contact ? this.contact.isPublic : true,
      isValid: true,
    };

    this.loaderService.start();
    this.contactService.save(contact).subscribe(() => {
      this.loaderService.stop();
      this.actionEmitter.emit();
    });
  }

  hasOneContactAtLeast(): boolean {
    return (this.form.controls.mobile.value && this.form.controls.mobile.valid)
      || (this.form.controls.mail.value && this.form.controls.mail.valid)
      || (this.form.controls.telephone.value && this.form.controls.telephone.valid)
      || (this.form.controls.fax.value && this.form.controls.fax.valid);
  }
}
